import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Subject, takeUntil } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Province } from '../../interfaces/province';
import { BusinessSearchResultRepositoryService } from '../../services/business-search-results/business-search-result-repository.service';
import { BusinessSearchResult } from '../../interfaces/business-search-results/business-search-result';
import { ListComponent } from '../list-component';
import { animate, query, state, style, transition, trigger, sequence } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderRepositoryService } from '../../services/order/order-repository-service.service';
import { Router } from '@angular/router';
import { SelectOption } from '../../interfaces/select-option';
import { eOrderStatusType, eOrderType, eResultState, eResultStateID } from '../../enums';
import { BusinessReportForBusinessSearchResult } from '../../interfaces/business-reports-for-business-search-result/business-search-result';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'lib-business-search-results',
  templateUrl: './business-search-results.component.html',
  styleUrls: ['./business-search-results.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('loadingToLoaded', [
      transition('* => Complete', [
        sequence([
          // Animate the spinner out first
          query('.spinner-animation', [
            style({ height: '*', opacity: 1 }),
            animate('300ms ease-in', style({ height: '0', opacity: 0 }))
          ], { optional: true }),
    
          // After spinner is fully gone, animate results in
          query('.results-animation', [
            style({ height: '0', opacity: 0, overflow: 'hidden' }),
            animate('300ms ease-out', style({ height: '*', opacity: 1 }))
          ], { optional: true })
        ])
      ])
    ])
  ],
})
export class BusinessSearchResultsComponent extends ListComponent<BusinessSearchResult> implements OnInit, OnDestroy {
  @Input() showOrderReportButton: boolean = true;
  @Input() isAdmin: boolean = false;
  @Input() showHiddenFilter: boolean = false;
  order!: Order;
  businessSearchResults!: BusinessSearchResult[];
  protected _expandedResult: BusinessSearchResult | null = null;
  displayedColumns: string[] = ['name', 'number', 'jurisdiction', 'status', 'actions'];
  columnsToDisplayWithView = [...this.displayedColumns, 'indicator'];
  protected baseOrderDetails!: FormGroup;
  protected isSaving: boolean = false;

  protected resultJurisdictions: SelectOption[] = [];
  protected resultStatuses: SelectOption[] = [];
  protected resultTypes: SelectOption[] = [];
  protected resultDescriptions: SelectOption[] = [];
  protected resultJurisdictionStatuses: SelectOption[] = [];

  provinces: Province[] = [
    { value: 'AB', viewValue: 'AB' },
    { value: 'BC', viewValue: 'BC' },
    { value: 'MB', viewValue: 'MB' },
    { value: 'NB', viewValue: 'NB' },
    { value: 'NL', viewValue: 'NL' },
    { value: 'NS', viewValue: 'NS' },
    { value: 'ON', viewValue: 'ON' },
    { value: 'PE', viewValue: 'PE' },
    { value: 'QC', viewValue: 'QC' },
    { value: 'SK', viewValue: 'SK' },
    { value: 'NT', viewValue: 'NT' },
    { value: 'NU', viewValue: 'NU' },
    { value: 'YT', viewValue: 'YT' },
    { value: '_F', viewValue: 'FED' }
  ]

  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private router: Router,
    repo: BusinessSearchResultRepositoryService
  ) {
    super(repo)
  }

  ngOnInit(): void {
    this.order = this.orderManager.currentOrder.value;
    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order =>{
        this.order = order;
        this.repo.populateBaseLink([this.order.id]);

        let defaultParams = new HttpParams().set("SortColumn", "Score").set("SortDirection", "Asc").set("ShowResult", true);

        this.repo.getFirstPageSubscribable(defaultParams).subscribe();
        (this.repo as BusinessSearchResultRepositoryService).getBusinessSearchResultsFilters(this.order.id).subscribe(filters => {
          this.resultJurisdictions = filters.jurisdictions;
          if (this.resultJurisdictions.some(jurisdiction => jurisdiction.value === '_F')) {
            var index = this.resultJurisdictions.findIndex(jurisdiction => jurisdiction.value == '_F');
            this.resultJurisdictions.at(index)!.label = "FED";
          }
          this.resultTypes = filters.companyTypes;
          this.resultStatuses = filters.statuses;
          this.resultDescriptions = filters.descriptions;
          this.resultJurisdictionStatuses = filters.jurisdictionStatuses;
        });
      });

    if (this.isAdmin) {
      this.displayedColumns = this.displayedColumns.concat(["source","score"]);
      this.columnsToDisplayWithView = this.columnsToDisplayWithView.concat(["source","score"]);
    }

    if (this.showOrderReportButton == false) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'actions');
      this.columnsToDisplayWithView = [...this.displayedColumns, 'indicator'];
    }
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  getJurisdiction(result: BusinessSearchResult) {
    var jurisdiction = result.jurisdiction;
    if (result.jurisdiction == "_F") {
      jurisdiction = "FED";
    }

    if (this.isExtraProvincial(result)) {
      let homeJurisdiction = result.homeJurisdiction ? result.homeJurisdiction : 'unknown';
      jurisdiction = `${result.jurisdiction} (EP - ${homeJurisdiction != "_F" ? homeJurisdiction : "FED"})`;
    }

    return jurisdiction;
  }

  isExtraProvincial(result: BusinessSearchResult) {
    let extraProvincialTypes = ["EP_Corp","EP_FI","EP_Irnc","EP_LLP","EP_LP"];

    return (result.homeJurisdiction || extraProvincialTypes.includes(result.companyType ?? ""));
  }

  getExtraProvincialExplanation(result: BusinessSearchResult) {
    return `This is an extra-provincially registered entity with a home jurisdiction of ${result.homeJurisdiction != "_F" ? result.homeJurisdiction : "FED"}`
  }

  orderReport(result: BusinessSearchResult) {
    this.isSaving = true;
    const jurisdiction = result.jurisdiction;

    this.baseOrderDetails = this.formBuilder.group({
        referenceNumber: [this.order.referenceNumber, Validators.required],
        country: [this.order.country, Validators.required],
        jurisdiction: [jurisdiction, Validators.required],
        orderTypeID: [eOrderType.businessReport, Validators.required],
        orderGroupID: [this.order.orderGroup!.id, Validators.required],
        businessSearchResultID: [result.id, Validators.required],
        businessReportCriteria: this.formBuilder.group({
            number: [result.number],
            businessName: [result.name]
        }),
    });

    this.ordersRepo.createNewOrder(this.baseOrderDetails.value)
      .subscribe(result => {
        const originatingOrderID = this.order.id;
        this.isSaving = false;
        const order = (result as any).resource;
        this.order = order;
        this.orderManager.updateOrder(order);
        if (this.order.id) {
          this.router.navigate(['new-business-search', order.id], { 
            queryParams: {
              originatingOrderID: originatingOrderID,
              destination: "originating"
            } 
          });
        }
      })
  }

  navigateToOrder(result: BusinessSearchResult) {
    const timeOrderedReports = result.businessReports?.sort((a, b) => {
      return b.added.getTime() - a.added.getTime();
    });
  
    const mostRecentReport = timeOrderedReports?.[0];
  
    if (mostRecentReport) {
      this.router.navigate(['/orders', mostRecentReport.businessReportOrderID]);
    }
  }

  resultsOverflow() {
    return this.order.searchResult?.resultState.id == eResultState.resultsOverflow || 
      this.order.searchResult?.resultState.id == eResultStateID.resultsOverflow;
  }

  noResults() {
    return this.order.searchResult?.resultState.id == eResultState.noResult ||
      this.order.searchResult?.resultState.id == eResultStateID.noResult;
  }

  shouldShowOrderReportButton(result: BusinessSearchResult): boolean {
    const reportList = (result.businessReports ?? []);
    return reportList.length === 0;
  }

  shouldShowViewReportButton(result: BusinessSearchResult): boolean {
    return (result.businessReports ?? []).length > 0
  }

  shouldShowPendingReportButton(result: BusinessSearchResult): boolean {
    return this.filterPendingReports(result.businessReports).length > 0
  }

  filterPendingReports(reports: BusinessReportForBusinessSearchResult[] | null | undefined) {
    // if the report isnt in draft and isnt in a complete or cancelled status then it should be seen as pending
    return reports?.filter(report => report.businessReportOrderOrderStatusTypeID !== eOrderStatusType.draft
                                    && report.businessReportOrderOrderStatusTypeID !== eOrderStatusType.complete
                                    && report.businessReportOrderOrderStatusTypeID !== eOrderStatusType.cancelled) ?? [];
  }

  isReportAvailable(result: BusinessSearchResult): boolean {
    return result.isReportAvailable;
  }
}
